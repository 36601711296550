<template>
  <div class="main notice flex between">
    <div class="index-top-right">
      <div class="top-ri-tit">网站快速通道</div>
      <div class="top-ri-con">
        <div class="ri-con-nav flex pointer" @click="toUrl('/exam')">
          <img src="@/assets/a-bg.png" class="nav-bg" />
          <img src="@/assets/a-1.png" class="nav-icon" />
          <div class="con-nav-tit">
            <div class="con-nav-tit1">考试报名</div>
            <div class="con-nav-tit2">Examination registration</div>
          </div>
        </div>
        <div class="ri-con-nav flex pointer" @click="toUrl('/print')">
          <img src="@/assets/a-bg.png" class="nav-bg" />
          <img src="@/assets/a-2.png" class="nav-icon" />
          <div class="con-nav-tit">
            <div class="con-nav-tit1">打印准考证</div>
            <div class="con-nav-tit2">Print admission ticket</div>
          </div>
        </div>
        <div class="ri-con-nav flex pointer" @click="toUrl('/score')">
          <img src="@/assets/a-bg.png" class="nav-bg" />
          <img src="@/assets/a-3.png" class="nav-icon" />
          <div class="con-nav-tit">
            <div class="con-nav-tit1">成绩查询</div>
            <div class="con-nav-tit2">Score query</div>
          </div>
        </div>
      </div>
    </div>
    <div class="notice-right">
      <div class="border-bg">
        <div class="border-tit">当前位置 > 首页 > 疑难解答</div>
        <div class="notice-list">
          <div
            class="list-item flex between center pointer"
            v-for="(item, index) in list"
            :key="index"
            @click="toInfo(item.id)"
          >
            <span></span>
            <div class="list-item-tit">
              {{ item.title }}
            </div>
            <div class="list-item-time">{{ item.create_time }}</div>
          </div>
          <div class="zan" v-if="total == 0">暂无数据</div>
        </div>
      </div>
      <div class="block" v-if="total != 0">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { articleLists } from "@/api/apiData";

export default {
  data() {
    return {
      page: 1,
      size: 10,
      list: [],
      total: 1,
    };
  },
  mounted() {
    this.$bus.emit("navS", 7);
    this.getList();
  },
  methods: {
    getList() {
      let obj = {
        page: this.page,
        size: this.size,
        channel_id: 2,
      };
      articleLists(obj).then((res) => {
        if (res.code == 1) {
          this.list = res.data.list;
          this.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.getList();
    },
    toInfo(id) {
      this.$router.push({
        path: "/noticeInfo",
        query: { id: id, type: 1 },
      });
    },
    toUrl(u) {
      this.$router.push(u);
    },
  },
};
</script>
<style lang="less" scoped>
.notice {
  margin-top: 20px;
  .index-top-right {
    width: 295px;
    .top-ri-tit {
      font-size: 14px;
      background: #58beff;
      color: white;
      width: fit-content;
      padding: 5px 10px;
    }
    .top-ri-con {
      background: #e5f5ff;
      padding: 18px 20px;
      .ri-con-nav {
        position: relative;
        width: 255px;
        height: 70px;
        margin-bottom: 20px;
        .nav-bg {
          width: 100%;
          height: 100%;
          position: absolute;
        }
        .nav-icon {
          width: 40px;
          height: 40px;
          position: absolute;
          left: 16px;
          top: 16px;
        }
        .con-nav-tit {
          position: absolute;
          color: white;
          top: 15px;
          left: 72px;
          width: 80%;
          .con-nav-tit1 {
            font-size: 18px;
          }
          .con-nav-tit2 {
            font-size: 10px;
          }
        }
      }
      .ri-con-nav:nth-child(3) {
        margin-bottom: 0;
      }
    }
  }
  .notice-right {
    width: 73%;
    .block {
      text-align: center;
      margin-top: 20px;
    }
    .border-bg {
      font-size: 14px;
      .list-item {
        padding: 10px 30px;
        span {
          background: #b4b4b4;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          display: inline-block;
        }
        .list-item-tit {
          width: 80%;
        }
      }
    }
  }
}
</style>